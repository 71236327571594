import { useAuth0 } from "@auth0/auth0-react";
import { getFuelPlan, sendEmail } from "api";
import { default as classNames, default as cn } from "classnames";
import Button from "components/button";
import LocationSearcher from "components/location-searcher/location-searcher";
import { useTractors } from "contexts/tractors-context";
import { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { AiFillExclamationCircle } from "react-icons/ai";
import { BiCommentError, BiRefresh } from "react-icons/bi";
import ReactSelect from "react-select";
import { v4 as uuidv4 } from "uuid";

const SingleTractorForm = ({ setFuelPlan }) => {
  const INITIAL_FORM_STATE = {
    tractor: null,
    tractorFuel: 15,
    originLocation: null,
    waypoints: [],
    destinationLocation: null,
  };

  const { user } = useAuth0();
  const [fuelPlanError, setFuelPlanError] = useState(null);
  const { tractors, loading, getTractors, updatedAt } = useTractors();
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState,
    getValues,
  } = useForm({
    defaultValues: INITIAL_FORM_STATE,
  });
  const { isSubmitting } = formState;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "waypoints",
  });

  const [tractorWatch, tractorFuelWatch] = watch(["tractor", "tractorFuel"]);

  const getFuelPlanHandlerWithTimeout = async (formData) => {
    const timeout = new Promise((_, reject) =>
      setTimeout(
        () =>
          reject(
            new Error(
              "Timeout: getFuelPlanHandler did not resolve in 10 seconds",
            ),
          ),
        10000,
      ),
    );

    try {
      await Promise.race([getFuelPlanHandler(formData), timeout]);
    } catch (error) {
      if (error.message.startsWith("Timeout")) {
        await sendEmail({
          to: ["sales@ifuelsmart.com"],
          subject: "Fuel Solution Generation: Exceeded 10 seconds",
          html: JSON.stringify({
            customer: user["https://ifuelsmart.com/company"],
            origin: formData.originLocation.description,
            destination: formData.destinationLocation.description,
            via: formData.waypoints.map(
              (waypointInformation) => waypointInformation.waypoint.description,
            ),
            tractorFuel: formData.tractorFuel,
            tractorFuelCapacity: formData.tractor.tank_capacity,
            MPG: formData.tractor.MPG,
          }),
        });
      } else {
        console.error("An error occurred:", error);
      }
    }
  };

  const getFuelPlanHandler = async (formData) => {
    setFuelPlanError(null);
    try {
      const { data } = await getFuelPlan({
        customer: user["https://ifuelsmart.com/company"],
        origin: formData.originLocation.description,
        destination: formData.destinationLocation.description,
        via: formData.waypoints.map(
          (waypointInformation) => waypointInformation.waypoint.description,
        ),
        tractorFuel: formData.tractorFuel,
        tractorFuelCapacity: formData.tractor.tank_capacity,
        MPG: formData.tractor.MPG,
      });

      // Check error here because 200 http status is returned
      if (Object.hasOwn(data, "error")) {
        setFuelPlanError(data.error);
        return;
      }

      setFuelPlan({
        ...data,
        fuelPurchaseLocations: data.fuelPurchaseLocations.reduce(
          (filtered, fpl) => {
            if (fpl.include) {
              filtered.push({ loc_id: uuidv4(), active: true, ...fpl });
            }
            return filtered;
          },
          [],
        ),
        // tractor: formData.tractor,
        tractor: {
          ...formData.tractor,
          fuelLevelInGallons: Number(formData.tractorFuel),
        },
      });
    } catch (e) {}
  };

  const handleRefreshTractor = () => {
    reset({ ...getValues(), tractor: null, tractorFuel: 15 });
    getTractors();
  };

  return (
    <form
      className="px-5 py-5 flex flex-col h-full flex-shrink-0 overflow-y-auto"
      onSubmit={handleSubmit(getFuelPlanHandlerWithTimeout)}
      autoComplete="off"
    >
      {/* Alert */}
      {fuelPlanError && (
        <div
          class="bg-red-100 border border-red-500 text-red-700 px-4 py-3 rounded relative mb-4 flex items-center"
          role="alert"
        >
          <BiCommentError className="mr-2" />
          {fuelPlanError}
        </div>
      )}

      <Controller
        name="tractor"
        control={control}
        rules={{ required: "Tractor is required" }}
        render={({ field, fieldState: { error } }) => {
          return (
            <div className="mb-4 relative">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="username"
              >
                Tractor{" "}
                <span className="text-xs text-gray-500">
                  Updated at{" "}
                  {updatedAt?.toLocaleString("en-US", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </span>
              </label>
              <ReactSelect
                className="tractor"
                classNamePrefix="tractor-select"
                options={tractors.map((tractor, i) => ({
                  label: tractor.unit_number,
                  value: tractor,
                }))}
                onChange={(option) => {
                  field.onChange(option.value);
                  setValue(
                    "tractorFuel",
                    option.value?.fuelLevelInGallons ?? "15",
                  );
                }}
                value={
                  tractorWatch
                    ? { label: tractorWatch.unit_number, value: tractorWatch }
                    : null
                }
              />

              <BiRefresh
                className={cn(
                  "absolute text-xl text-gray-500 top-[38px] right-12 cursor-pointer",
                  {
                    "animate-spin text-red-500": loading,
                  },
                )}
                onClick={handleRefreshTractor}
              />

              {error && (
                <div className="text-red-600 mt-1 flex items-center gap-1">
                  <AiFillExclamationCircle />
                  <span className="text-sm font-medium">{error.message}</span>
                </div>
              )}
            </div>
          );
        }}
      />

      <Controller
        name="tractorFuel"
        control={control}
        render={({ field }) => (
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-1">
              Tractor Fuel (
              {tractorWatch
                ? `${field.value} gallons or ${Math.round((field.value / tractorWatch.tank_capacity) * 100)}%`
                : "Select truck first"}
              )
            </label>
            <input
              type="range"
              className="block w-full"
              min="15"
              max={tractorWatch?.tank_capacity}
              value={
                tractorFuelWatch
                  ? tractorFuelWatch
                  : tractorWatch?.fuelLevelInGallons || "15"
              }
              onChange={field.onChange}
              disabled={!tractorWatch}
            />
          </div>
        )}
      />
      <Controller
        name="originLocation"
        control={control}
        rules={{ required: "Origin required" }}
        render={({ field, fieldState: { error } }) => (
          <LocationSearcher
            label="Origin"
            placeholder="Enter origin location"
            onSuggestSelect={field.onChange}
            error={error ? error.message : null}
            initialValue={field.value?.description}
            value={field.value?.description}
          />
        )}
      />

      {/* Via */}
      <button
        type="button"
        className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded w-full mb-4"
        onClick={() => append({ waypoint: null })}
        disabled={fields.length === 4}
      >
        Add Stop
      </button>

      {fields.map((field, i) => (
        <div key={field.id} className="flex">
          <Controller
            name={`waypoints[${i}].waypoint`}
            control={control}
            rules={{ required: "Stop required" }}
            render={({ field, fieldState: { error } }) => {
              return (
                <LocationSearcher
                  label=""
                  placeholder="Enter stop location"
                  onSuggestSelect={field.onChange}
                  error={error ? error.message : null}
                  initialValue={field.value?.description}
                  value={field.value?.description}
                />
              );
            }}
          />
          <div
            className="bg-red-500 text-white flex items-center h-[38px] mt-2 px-4 rounded-sm cursor-pointer"
            onClick={() => remove(i)}
          >
            X
          </div>
        </div>
      ))}

      {/* Destination */}
      <Controller
        name="destinationLocation"
        control={control}
        rules={{ required: "Destination required" }}
        render={({ field, fieldState: { error } }) => {
          return (
            <LocationSearcher
              label="Destination"
              placeholder="Enter destination location"
              onSuggestSelect={field.onChange}
              error={error ? error.message : null}
              initialValue={field.value?.description}
            />
          );
        }}
      />

      {/* Actions */}
      <div className="flex space-x-3 mt-auto">
        <Button
          variant="lightGray"
          type="button"
          onClick={() => {
            reset(INITIAL_FORM_STATE);
          }}
        >
          Reset
        </Button>
        <button
          type="submit"
          className="bg-orange-600 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded w-full"
        >
          {isSubmitting ? (
            <>
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white inline-block"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
              Processing
            </>
          ) : (
            "Get Fuel Plan"
          )}
        </button>
      </div>
    </form>
  );
};

const MultiTractorForm = ({ setFuelPlan }) => {
  const INITIAL_FORM_STATE = {
    tractors: null,
    originLocation: null,
    waypoints: [],
    destinationLocation: null,
  };
  const { user } = useAuth0();

  const [fuelPlanError, setFuelPlanError] = useState(null);
  const { tractors } = useTractors();
  const { handleSubmit, control, watch, reset, formState } = useForm({
    defaultValues: INITIAL_FORM_STATE,
  });
  const { isSubmitting } = formState;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "waypoints",
  });

  const [tractorsWatch] = watch(["tractors"]);

  const getFuelPlanHandler = async (formData) => {
    setFuelPlanError(null);
    try {
      // const [createdFuelPlans] = await Promise.all(formData.);
      const fuelPlansResponse = await Promise.all(
        formData.tractors.map((tractor) => {
          return getFuelPlan({
            customer: user["https://ifuelsmart.com/company"],
            origin: formData.originLocation.description,
            destination: formData.destinationLocation.description,
            via: formData.waypoints.map(
              (waypointInformation) => waypointInformation.waypoint.description,
            ),
            tractorFuel: tractor.fuelLevelInGallons,
            tractorFuelCapacity: tractor.tank_capacity,
            MPG: tractor.MPG,
          });
        }),
      );

      let error = "";
      fuelPlansResponse.forEach(({ data }, idx) => {
        if (!Object.hasOwn(data, "error")) {
          if (idx === 0)
            error += `Not enough fuel on tractor(s) ${formData.tractors[idx].unit_number}`;
          else error += `, ${formData.tractors[idx].unit_number}`;
        }
      });

      if (error) setFuelPlanError(error);

      setFuelPlan(
        fuelPlansResponse.map(({ data }, idx) => {
          return {
            ...data,
            fuelPurchaseLocations: data.fuelPurchaseLocations.reduce(
              (filtered, fpl) => {
                if (fpl.include) {
                  filtered.push({ loc_id: uuidv4(), ...fpl });
                }
                return filtered;
              },
              [],
            ),
            tractor: formData.tractors[idx],
          };
        }),
      );

      // setFuelPlan({
      //   ...data,
      //   fuelPurchaseLocations: data.fuelPurchaseLocations.reduce((filtered, fpl) => {
      //     if (fpl.include) {
      //       filtered.push({ loc_id: uuidv4(), ...fpl });
      //     }
      //     return filtered;
      //   }, []),
      //   tractor: formData.tractor,
      // });
    } catch (e) {}
  };

  return (
    <form
      className="px-5 py-5 flex flex-col h-full flex-shrink-0 overflow-y-auto"
      onSubmit={handleSubmit(getFuelPlanHandler)}
      autoComplete="off"
    >
      {/* Alert */}
      {fuelPlanError && (
        <div
          className="bg-red-100 border border-red-500 text-red-700 px-4 py-3 rounded relative mb-4 flex items-center"
          role="alert"
        >
          {fuelPlanError}
        </div>
      )}

      <Controller
        name="tractors"
        control={control}
        rules={{ required: "Tractor is required" }}
        render={({ field, fieldState: { error } }) => {
          return (
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="username"
              >
                Tractor
              </label>
              <ReactSelect
                isMulti
                className="tractor"
                classNamePrefix="tractor-select"
                options={tractors.map((tractor, i) => ({
                  label: `${tractor.unit_number} ${!tractor.driver_id ? " - Driver not assigned" : ""}`,
                  value: tractor,
                  disabled: tractor.driver_id === null,
                }))}
                onChange={(options) => {
                  field.onChange(options.map((option) => option.value));
                }}
                // value={tractorsWatch?.length ? tractorsWatch : null}n
                value={
                  tractorsWatch
                    ? tractorsWatch.map((tractor) => ({
                        label: tractor.unit_number,
                        value: tractor,
                      }))
                    : null
                }
                isOptionDisabled={(option) => option.disabled}
              />

              {error && (
                <div className="text-red-600 mt-1 flex items-center gap-1">
                  <AiFillExclamationCircle />
                  <span className="text-sm font-medium">{error.message}</span>
                </div>
              )}
            </div>
          );
        }}
      />

      <Controller
        name="originLocation"
        control={control}
        rules={{ required: "Origin required" }}
        render={({ field, fieldState: { error } }) => (
          <LocationSearcher
            label="Origin"
            placeholder="Enter origin location"
            onSuggestSelect={field.onChange}
            error={error ? error.message : null}
            initialValue={field.value?.description}
          />
        )}
      />

      {/* Via */}
      <button
        type="button"
        className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded w-full mb-4"
        onClick={() => append({ waypoint: null })}
        disabled={fields.length === 2}
      >
        Add Stop
      </button>

      {fields.map((field, i) => (
        <div key={field.id} className="flex">
          <Controller
            name={`waypoints[${i}].waypoint`}
            control={control}
            rules={{ required: "Stop required" }}
            render={({ field, fieldState: { error } }) => {
              return (
                <LocationSearcher
                  label=""
                  placeholder="Enter stop location"
                  onSuggestSelect={field.onChange}
                  error={error ? error.message : null}
                  initialValue={field.value?.description}
                />
              );
            }}
          />
          <div
            className="bg-red-500 text-white flex items-center h-[38px] mt-2 px-4 rounded-sm cursor-pointer"
            onClick={() => remove(i)}
          >
            X
          </div>
        </div>
      ))}

      {/* Destination */}
      <Controller
        name="destinationLocation"
        control={control}
        rules={{ required: "Destination required" }}
        render={({ field, fieldState: { error } }) => {
          return (
            <LocationSearcher
              label="Destination"
              placeholder="Enter destination location"
              onSuggestSelect={field.onChange}
              error={error ? error.message : null}
              initialValue={field.value?.description}
            />
          );
        }}
      />

      {/* Actions */}
      <div className="flex space-x-3 mt-auto">
        <Button
          variant="lightGray"
          type="button"
          onClick={() => {
            reset(INITIAL_FORM_STATE);
          }}
        >
          Reset
        </Button>
        <button
          type="submit"
          className="bg-orange-600 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded w-full"
        >
          {isSubmitting ? (
            <>
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white inline-block"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
              Processing
            </>
          ) : (
            "Get Fuel Plan"
          )}
        </button>
      </div>
    </form>
  );
};

const FormLayout = ({ setFuelPlan }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <>
      <div className="flex flex-col h-full flex-shrink-0 overflow-y-auto">
        <div className="flex">
          <button
            className={classNames(
              "w-full py-2.5 text-sm font-medium border-b border-gray-100 focus:outline-none text-center",
              { "bg-orange-600 text-white": selectedTab === 0 },
              { "hover:bg-gray-100": selectedTab !== 0 },
            )}
            onClick={() => setSelectedTab(0)}
          >
            Single Tractor
          </button>
          <button
            className={classNames(
              "w-full py-2.5 text-sm font-medium border-b border-gray-100 focus:outline-none text-center",
              { "bg-orange-600 text-white": selectedTab === 1 },
              { "hover:bg-gray-100": selectedTab !== 1 },
            )}
            onClick={() => setSelectedTab(1)}
          >
            Multi Tractor
          </button>
        </div>

        <div className="flex-1">
          {selectedTab === 0 && <SingleTractorForm setFuelPlan={setFuelPlan} />}
          {selectedTab === 1 && <MultiTractorForm setFuelPlan={setFuelPlan} />}
        </div>
      </div>
    </>
  );
};

export default FormLayout;
