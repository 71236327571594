import { useAuth0 } from "@auth0/auth0-react";
import { Tab } from "@headlessui/react";
import { queueFuelPlan, sendMessage, storeFuelPlan } from "api";
import cn from "classnames";
import Button from "components/button";
import dashboardConfig from "dashboard-config";
import { useState } from "react";
import { BiExit } from "react-icons/bi";
import { FaGasPump } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";

const FuelPlan = ({ fuelPlan, setFuelPlan, resetFuelPlan }) => {
  const { user } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState();
  // For multi tractors
  const [successNotification, setSuccessNotification] = useState("");
  const [errorNotification, setErrorNotifcation] = useState("");
  const [disableSendFuelPlan, setDisableSendFuelPlan] = useState(false);
  const [isEditFuelPlan, setIsEditFuelPlan] = useState(false);

  const sendFuelPlan = async () => {
    setLoading(true);

    const customerCode = user["https://ifuelsmart.com/company"];

    let message = "Fuel Plan: \n";
    fuelPlan.fuelPurchaseLocations.forEach((fpl, idx) => {
      message += `
      
      ${idx + 1}. ${fpl.location}:
      - ${fpl.fuelToPurchase === -99 ? " Top off tractor" : ` Purchase ${fpl.fuelToPurchase} gallons`}
      -  Exit: ${fpl.interstate_exit}
      \n`;
    });

    // If there is no driver assigned to the tractor, we need to queue the fuel plan
    if (fuelPlan.tractor.driver_id === null) {
      try {
        await queueFuelPlan(user["https://ifuelsmart.com/company"], fuelPlan.tractor.unit_number, {
          origin: fuelPlan.origin,
          destination: fuelPlan.destination,
          directionsVia: fuelPlan.directionsVia,
          totalTripDistanceMiles: fuelPlan.totalTripDistanceMiles,
          totalFuelNeededGallons: fuelPlan.totalFuelNeededGallons,
          totalPurchaseNeededGallons: fuelPlan.totalPurchaseNeededGallons,
          fuelPurchaseLocations: fuelPlan.fuelPurchaseLocations,
          fuelStationOnRoute: fuelPlan.fuelStationOnRoute,
          tractor: fuelPlan.tractor, // queue fuel plan processor relies on this,
          savings: fuelPlan?.ifsOptimizedSavings ?? null,
        });

        setNotification({ type: "success", message: "Fuel plan queued" });
        setDisableSendFuelPlan(true);
        setLoading(false);
      } catch (error) {
        setNotification({
          type: "error",
          message: error.response.data.message,
        });
        setLoading(false);
      }

      return;
    }

    // If there is a driver assigned to the tractor, we send the fuel plan to the driver
    try {
      if (["pg1", "ind", "lfc", "logi"].includes(customerCode)) {
        if (fuelPlan.tractor.phoneNumber === null) {
          setNotification({ type: "error", message: "No Phone #" });
          setDisableSendFuelPlan(true);
          setLoading(false);
          return;
        }

        await sendMessage({
          customer: customerCode,
          driver: {
            name: fuelPlan.tractor.driver_name,
            phoneNumber: fuelPlan.tractor.phoneNumber,
          },
          fuelPurchaseLocations: fuelPlan.fuelPurchaseLocations,
        });

        await storeFuelPlan(user["https://ifuelsmart.com/company"], fuelPlan.tractor.unit_number, {
          origin: fuelPlan.origin,
          destination: fuelPlan.destination,
          directionsVia: fuelPlan.directionsVia,
          totalTripDistanceMiles: fuelPlan.totalTripDistanceMiles,
          totalFuelNeededGallons: fuelPlan.totalFuelNeededGallons,
          totalPurchaseNeededGallons: fuelPlan.totalPurchaseNeededGallons,
          fuelPurchaseLocations: fuelPlan.fuelPurchaseLocations,
          fuelStationOnRoute: fuelPlan.fuelStationOnRoute,
          savings: fuelPlan?.ifsOptimizedSavings ?? null, // Not entirely sure if this is present since we have different algos
        });

        setNotification({ type: "success", message: "Fuel plan sent" });
        setDisableSendFuelPlan(true);
        setLoading(false);

        return;
      }

      // solid old implementatino
      await sendMessage({
        to: fuelPlan.tractor.driver_id,
        body: message.replace(/[^\S\r\n]+/g, " "),
        customer: user["https://ifuelsmart.com/company"],
      });

      await storeFuelPlan(user["https://ifuelsmart.com/company"], fuelPlan.tractor.unit_number, {
        origin: fuelPlan.origin,
        destination: fuelPlan.destination,
        directionsVia: fuelPlan.directionsVia,
        totalTripDistanceMiles: fuelPlan.totalTripDistanceMiles,
        totalFuelNeededGallons: fuelPlan.totalFuelNeededGallons,
        totalPurchaseNeededGallons: fuelPlan.totalPurchaseNeededGallons,
        fuelPurchaseLocations: fuelPlan.fuelPurchaseLocations,
        fuelStationOnRoute: fuelPlan.fuelStationOnRoute,
        savings: fuelPlan?.ifsOptimizedSavings ?? null, // Not entirely sure if this is present since we have different algos
      });

      setNotification({ type: "success", message: "Fuel plan sent" });
      setDisableSendFuelPlan(true);
      setLoading(false);
    } catch (error) {
      setNotification({ type: "error", message: error.response.data.message });
      setLoading(false);
    }
  };

  // Send multi-tractor fuel plans
  const sendFuelPlans = async () => {
    setErrorNotifcation("");
    setSuccessNotification("");
    setLoading(true);

    const sendFuelPlanResponses = await Promise.allSettled(
      fuelPlan.map(async (singleFuelPlan, i) => {
        // Do this for each fuel plan
        let message = "Fuel Plan: \n";
        singleFuelPlan.fuelPurchaseLocations.forEach((fpl, idx) => {
          message += `
            ${idx + 1}. ${fpl.location}:
            - ${fpl.fuelToPurchase === -99 ? " Top off tractor" : ` Purchase ${fpl.fuelToPurchase} gallons`}
            -  Exit: ${fpl.interstate_exit}
            \n`;
        });

        await sendMessage({
          to: singleFuelPlan.tractor.driver_id,
          body: message.replace(/[^\S\r\n]+/g, " "),
          customer: user["https://ifuelsmart.com/company"],
        });

        storeFuelPlan(user["https://ifuelsmart.com/company"], singleFuelPlan.tractor.unit_number, {
          origin: singleFuelPlan.origin,
          destination: singleFuelPlan.destination,
          directionsVia: singleFuelPlan.directionsVia,
          totalTripDistanceMiles: singleFuelPlan.totalTripDistanceMiles,
          totalFuelNeededGallons: singleFuelPlan.totalFuelNeededGallons,
          totalPurchaseNeededGallons: singleFuelPlan.totalPurchaseNeededGallons,
          fuelPurchaseLocations: singleFuelPlan.fuelPurchaseLocations,
          fuelStationOnRoute: singleFuelPlan.fuelStationOnRoute,
          savings: singleFuelPlan?.ifsOptimizedSavings ?? null, // Not entirely sure if this is present since we have different algos
        });
      })
    );

    let failedToSendTractors = [];
    let successfullySentTractors = [];

    sendFuelPlanResponses.forEach((fuelPlansResponse, idx) => {
      if (fuelPlansResponse.status === "rejected") {
        failedToSendTractors.push(fuelPlan[idx].tractor.unit_number);
      } else if (fuelPlansResponse.status === "fulfilled") {
        successfullySentTractors.push(fuelPlan[idx].tractor.unit_number);
      }
    });

    if (failedToSendTractors.length) {
      setErrorNotifcation(`Failed to send fuel plan to ${failedToSendTractors.join(", ")}`);
    }
    if (successfullySentTractors.length) {
      setSuccessNotification(`Successfully sent fuel plan to ${successfullySentTractors.join(", ")}`);
    }

    setDisableSendFuelPlan(true);
    setLoading(false);
  };

  if (Array.isArray(fuelPlan)) {
    return (
      <div className="flex flex-col h-full">
        <div className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mx-5 my-4">
          Viewing fuel plan is only available in single tractor mode
        </div>
        {/* Alert */}
        {errorNotification && (
          <div
            className="bg-red-100 border border-red-500 text-red-700 px-4 py-3 rounded relative mb-4 flex items-center mx-5"
            role="alert"
          >
            {errorNotification}
          </div>
        )}
        {successNotification && (
          <div
            className="bg-green-100 border border-green-500 text-green-700 px-4 py-3 rounded relative mb-4 flex items-center mx-5"
            role="alert"
          >
            {successNotification}
          </div>
        )}
        <div className="border-t flex-0 bg-gray-50 mt-auto" style={{ flex: "0 0 auto" }}>
          {/* Actions */}
          <div className="flex items-center space-x-3 px-5 py-3">
            <Button variant="lightGray" onClick={resetFuelPlan}>
              Back
            </Button>
            <Button className="" disabled={disableSendFuelPlan} loading={loading} onClick={sendFuelPlans}>
              Send Fuel Plans
            </Button>
          </div>
        </div>
      </div>
    );
  }

  const hanldeActivateFuelStop = (idx) => {
    // First we need to check if we have required inputs which is lcoation and fuel
    if (
      fuelPlan.fuelPurchaseLocations[idx].location === "" ||
      !fuelPlan.fuelPurchaseLocations[idx].fuelToPurchase === ""
    ) {
      return;
    }

    const newFuelPurchaseLocations = fuelPlan.fuelPurchaseLocations.map((fpl, i) => {
      if (i === idx) {
        return {
          ...fpl,
          active: true,
        };
      } else {
        return fpl;
      }
    });
    setFuelPlan({
      ...fuelPlan,
      fuelPurchaseLocations: newFuelPurchaseLocations,
    });
  };

  return (
    <div className="flex flex-col h-full">
      <Tab.Group>
        <Tab.List className="flex flex-initial">
          <Tab
            className={({ selected }) =>
              cn(
                "w-full py-2.5 text-sm font-medium border-b border-gray-100 focus:outline-none",
                selected ? "bg-orange-600 text-white" : "hover:bg-gray-100"
              )
            }
          >
            IFS Recommended
          </Tab>
          <Tab
            className={({ selected }) =>
              cn(
                "w-full py-2.5 text-sm font-medium border-b border-gray-200 focus:outline-none",
                selected ? "bg-orange-600 text-white" : " hover:bg-gray-100"
              )
            }
          >
            All Fuel Stations
          </Tab>
        </Tab.List>
        <Tab.Panels className="h-full overflow-hidden">
          <Tab.Panel className="h-full flex flex-col">
            {/* Actions to edit fuel solution */}
            {["anr", "we1", "ist", "pg1", "lfc", "logi", "ind"].includes(user["https://ifuelsmart.com/company"]) && (
              <div className="px-4 pt-2 pb-2 border-b space-x-2">
                {isEditFuelPlan ? (
                  <>
                    <button
                      type="button"
                      className="rounded bg-green-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                      onClick={() => setIsEditFuelPlan(false)}
                    >
                      Finish
                    </button>
                    <button
                      type="button"
                      className="rounded bg-gray-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                      onClick={() => {
                        const newFuelPurchaseLocations = fuelPlan.fuelPurchaseLocations.concat({
                          location: "",
                          price: 0,
                          fuelToPurchase: 0,
                          lat: 0,
                          lng: 0,
                          interstate_exit: "",
                          active: false,
                          loc_id: uuidv4(),
                        });

                        setFuelPlan(() => {
                          return {
                            ...fuelPlan,
                            fuelPurchaseLocations: newFuelPurchaseLocations,
                          };
                        });
                      }}
                    >
                      Add
                    </button>
                  </>
                ) : (
                  <button
                    type="button"
                    className="rounded bg-orange-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                    onClick={() => setIsEditFuelPlan(true)}
                  >
                    Edit
                  </button>
                )}
              </div>
            )}

            <div className="flex-1 overflow-auto">
              {isEditFuelPlan ? (
                <div className="px-4 my-4 space-y-4">
                  {fuelPlan.fuelPurchaseLocations.map((fpl, idx) => (
                    <div key={idx} className="grid grid-cols-6 gap-x-2.5">
                      {/* Fuel Station  */}
                      <div className="col-span-4">
                        <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                          Fuel Location
                        </label>
                        <select
                          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value={fpl.location}
                          onChange={(e) => {
                            const selectedFuelLocation = e.target.value;

                            if (selectedFuelLocation === "") {
                              // If user select empty location, we need to set the values to empty
                              const newFuelPurchaseLocations = fuelPlan.fuelPurchaseLocations.map((fpl, i) => {
                                if (i === idx) {
                                  return {
                                    ...fpl,
                                    location: "",
                                    price: 0,
                                    fuelToPurchase: 0,
                                    lat: 0,
                                    lng: 0,
                                    interstate_exit: "",
                                    active: false,
                                  };
                                } else {
                                  return fpl;
                                }
                              });

                              setFuelPlan(() => {
                                return {
                                  ...fuelPlan,
                                  fuelPurchaseLocations: newFuelPurchaseLocations,
                                };
                              });

                              return;
                            }

                            const fuelLocation = fuelPlan.fuelStationOnRoute.data.find(
                              (fsor) => fsor[0] === selectedFuelLocation
                            );
                            const fuelLocationPrice = fuelLocation[1];
                            const fuelLocationLat = fuelLocation[2];
                            const fuelLocationLon = fuelLocation[3];
                            const fuelLocationInterstate = fuelLocation[4];

                            const newFuelPurchaseLocations = fuelPlan.fuelPurchaseLocations.map((fpl, i) => {
                              if (i === idx) {
                                return {
                                  ...fpl,
                                  location: selectedFuelLocation,
                                  price: fuelLocationPrice,
                                  lat: fuelLocationLat,
                                  lng: fuelLocationLon,
                                  interstate_exit: fuelLocationInterstate,
                                };
                              } else {
                                return fpl;
                              }
                            });

                            setFuelPlan(() => {
                              return {
                                ...fuelPlan,
                                fuelPurchaseLocations: newFuelPurchaseLocations,
                              };
                            });
                          }}
                        >
                          <option value="">Select Fuel location</option>
                          {fuelPlan.fuelStationOnRoute.data.map(([location], idx) => (
                            <option key={idx} value={location}>
                              {location}
                            </option>
                          ))}
                        </select>
                      </div>
                      {/* Fuel Amount */}
                      <div className="col-span-1">
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                          Fuel
                        </label>
                        <div className="mt-2">
                          <input
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Fuel amount"
                            value={fpl.fuelToPurchase === -99 ? fuelPlan.tractor.tank_capacity : fpl.fuelToPurchase}
                            onChange={(e) => {
                              const fuelToPurchase = e.target.value;

                              const newFuelPurchaseLocations = fuelPlan.fuelPurchaseLocations.map((fpl, i) => {
                                if (i === idx) {
                                  return {
                                    ...fpl,
                                    fuelToPurchase: fuelToPurchase === "" ? 0 : parseInt(fuelToPurchase),
                                  };
                                } else {
                                  return fpl;
                                }
                              });

                              setFuelPlan(() => {
                                return {
                                  ...fuelPlan,
                                  fuelPurchaseLocations: newFuelPurchaseLocations,
                                };
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/* Edit Fuel Stop Actions */}
                      <div className="col-span-1 self-end flex flex-col space-y-1">
                        {!fpl.active && (
                          <button
                            type="button"
                            className="rounded-md w-full bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                            // onClick={() => {
                            //   const newFuelPurchaseLocations = fuelPlan.fuelPurchaseLocations.map((fpl, i) => {
                            //     if (i === idx) {
                            //       return {
                            //         ...fpl,
                            //         active: true,
                            //       };
                            //     } else {
                            //       return fpl;
                            //     }
                            //   });
                            //   setFuelPlan({
                            //     ...fuelPlan,
                            //     fuelPurchaseLocations: newFuelPurchaseLocations,
                            //   });
                            // }}
                            onClick={() => hanldeActivateFuelStop(idx)}
                          >
                            &#10003;
                          </button>
                        )}

                        <button
                          type="button"
                          className="rounded-md w-full bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                          onClick={() => {
                            const newFuelPurchaseLocations = fuelPlan.fuelPurchaseLocations.filter((fpl, i) => {
                              return i !== idx;
                            });
                            setFuelPlan({
                              ...fuelPlan,
                              fuelPurchaseLocations: newFuelPurchaseLocations,
                            });
                          }}
                        >
                          X
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                fuelPlan.fuelPurchaseLocations.map((fpl, idx) => (
                  <div key={idx} className="border-b border-gray-200 last:border-0 px-5 py-3 text-sm">
                    <div className="font-semibold mb-2">
                      {idx + 1}. {fpl.location}
                    </div>
                    <div className="flex items-center mb-2">
                      <FaGasPump className="text-red-900 mr-2" />
                      <span>
                        {fpl.fuelToPurchase === -99
                          ? `Top off tractor at this location at `
                          : `Purchase ${fpl.fuelToPurchase} gallons at `}
                        <span className="bg-green-100 px-1 py-0.5 rounded">
                          {fpl.price.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}{" "}
                          / gal
                        </span>
                      </span>
                    </div>
                    <div className="flex items-center">
                      <BiExit className="mr-2" />
                      {fpl.interstate_exit}
                    </div>
                  </div>
                ))
              )}
            </div>

            {!isEditFuelPlan && (
              // Summary
              <div className="border-t flex-0 bg-gray-50 mt-auto" style={{ flex: "0 0 auto" }}>
                {/* Summary */}
                <div className="border-b">
                  {/* Row */}
                  <div className="flex justify-between px-5 pt-3 mb-2">
                    <div className="text-sm">
                      <div className="text-gray-600">Distance</div>
                      <div className="font-semibold ">{fuelPlan.totalTripDistanceMiles.toLocaleString()} Miles</div>
                    </div>
                    <div className="text-sm">
                      <div className="text-gray-600">IFS Savings</div>
                      <div className="font-semibold text-center">${fuelPlan.ifsOptimizedSavings}</div>
                    </div>
                    <div className="text-sm text-right">
                      <div className="text-gray-600 ">Fuel Stops</div>
                      <div className="font-semibold ">{fuelPlan.fuelPurchaseLocations.length}</div>
                    </div>
                    {/* <div className="text-sm text-right">
                      <div className="text-gray-600 ">Total Fuel</div>
                      <div className="font-semibold ">{fuelPlan.totalFuelNeededGallons.toLocaleString()} Gallons</div>
                    </div> */}
                  </div>
                  {/* Row */}
                  {/* <div className="flex justify-between px-5 pb-3">
                    <div className="text-sm">
                      <div className="text-gray-600">Fuel Purchase</div>
                      <div className="font-semibold ">
                        {fuelPlan.totalPurchaseNeededGallons.toLocaleString()} Gallons Gallons
                      </div>
                    </div>
                    <div className="text-sm text-right">
                      <div className="text-gray-600 ">Fuel Stops</div>
                      <div className="font-semibold ">{fuelPlan.fuelPurchaseLocations.length}</div>
                    </div>
                  </div> */}
                </div>
                {/* Mail Details */}
                <div className="border-b">
                  {/* Row */}
                  <div className="flex justify-between px-5 py-3">
                    <div className="text-sm">
                      <div className="text-gray-600">Tractor</div>
                      <div className="font-semibold ">{fuelPlan.tractor.unit_number}</div>
                    </div>
                    <div className="text-sm text-right">
                      <div className="text-gray-600 ">Driver</div>
                      <div className="font-semibold ">{fuelPlan.tractor.driver_name ?? "No Driver"}</div>
                    </div>
                  </div>
                </div>
                {/* Actions */}
                <div className="flex items-center space-x-3 px-5 py-3">
                  <Button variant="lightGray" onClick={resetFuelPlan}>
                    Back
                  </Button>
                  <Button
                    disabled={disableSendFuelPlan || loading}
                    loading={loading}
                    onClick={sendFuelPlan}
                    className={cn({
                      "bg-green-800 disabled:bg-green-800": notification?.type === "success",
                      "bg-red-800 disabled:bg-red-800": notification?.type === "error",
                    })}
                  >
                    {notification?.message ? notification?.message : "Send Fuel Plan"}
                  </Button>
                </div>
              </div>
            )}
          </Tab.Panel>
          <Tab.Panel className="h-full flex flex-col">
            <div className="overflow-auto">
              {fuelPlan.fuelStationOnRoute.data.map(([location, price], idx) => (
                <div key={idx} className="border-b border-gray-200 last:border-0 px-5 py-3 text-sm">
                  <div className="font-semibold mb-2">
                    {idx + 1}. {location}
                  </div>
                  <div className="flex items-center mb-2">
                    <FaGasPump className="text-red-900 mr-2" />
                    <span>
                      Fuel price at this location is{" "}
                      <span className="bg-green-100 px-1 py-0.5 rounded">
                        {price.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}{" "}
                        / gal
                      </span>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default FuelPlan;
