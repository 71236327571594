import { useEffect, useMemo, useState } from "react";
import * as api from "api/index";
import { useAuth0 } from "@auth0/auth0-react";
import { FuelSolution } from "types";
import {
  CellProps,
  Column,
  useFilters,
  useSortBy,
  useTable,
} from "react-table";
import { AiFillCaretDown } from "react-icons/ai";
import classNames from "classnames";
import dayjs from "dayjs";
import { FaRegTrashAlt } from "react-icons/fa";
import Swal from "sweetalert2";
import ColumnFilter from "pages/tractors-page/column-filter";

export function FuelSolutionsIndex() {
  const { user } = useAuth0();
  const [fuelSolutions, setFuelSolutions] = useState<FuelSolution[]>([]);
  const [fuelSolutionsLoading, setFuelSolutionsLoading] = useState(true);

  async function handleDeleteFuelSolution(fuelSolutionId: string) {
    const { value: password, isDismissed } = await Swal.fire({
      title: "Enter your password",
      input: "password",
      inputLabel: "Password",
      inputPlaceholder: "Enter your password",
      showCancelButton: true,
      confirmButtonColor: "#ea580c",
      cancelButtonColor: "#ef4444",
    });

    if (isDismissed) return;

    try {
      Swal.showLoading();
      await api.validateFuelSolutionPassword({
        password,
        companyCode: user!["https://ifuelsmart.com/company"],
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Invalid password",
        text: "Please try again",
        confirmButtonColor: "#ea580c",
      });
      return;
    }

    // show a swal that ask for confirmation for delete
    const swalConfirmation = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ea580c",
      cancelButtonColor: "#ef4444",
      confirmButtonText: "Yes, delete it!",
    });

    if (!swalConfirmation.isConfirmed) return;

    try {
      await api.deleteFuelSolution({
        companyCode: user!["https://ifuelsmart.com/company"],
        fuelSolutionId: fuelSolutionId,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "We were unable to delete the fuel solution. Please try again",
        confirmButtonColor: "#ea580c",
      });
    }

    setFuelSolutions((prev) =>
      prev.filter((fuelSolution) => fuelSolution.FSID !== fuelSolutionId),
    );
  }

  const columns = useMemo<Column<FuelSolution>[]>(
    () => [
      {
        Header: "Origin",
        accessor: "FSOrigin",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Destination",
        accessor: "FSDestination",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Status",
        accessor: "FSStatus",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: CellProps<FuelSolution>) => {
          return (
            <span
              className={classNames(
                "px-2 inline-flex text-xs leading-5 font-semibold rounded-full",
                value === "QUEUED" ? "bg-yellow-100 text-yellow-800" : "",
                value === "SENT" ? "bg-green-100 text-green-800" : "",
              )}
            >
              {value}
            </span>
          );
        },
      },
      {
        Header: "Unit Number",
        accessor: "DriverID",
        disableSortBy: true,
        Filter: ColumnFilter,
      },
      {
        Header: "Created At",
        accessor: "FSCreatedAt",
        disableFilters: true,
        Cell: ({ value }: CellProps<FuelSolution>) => {
          return <span>{dayjs(value).format("YYYY-MM-DD hh:mm A")}</span>;
        },
      },
      {
        Header: "Savings",
        accessor: "FSSavings",
        disableFilters: true,
        Cell: ({ value }: CellProps<FuelSolution>) => {
          return (
            <span>
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(value)}
            </span>
          );
        },
      },
      // add action column for buttons like delete
      {
        id: "actions",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }: CellProps<FuelSolution>) => {
          return (
            <div className="flex justify-center space-x-2">
              <button
                className="text-gray-400 hover:text-gray-500"
                onClick={() => handleDeleteFuelSolution(row.original.FSID)}
              >
                <FaRegTrashAlt />
              </button>
            </div>
          );
        },
      },
    ],
    [],
  );

  const table = useTable<FuelSolution>(
    {
      data: fuelSolutions,
      columns,
      initialState: {
        sortBy: [{ id: "FSCreatedAt", desc: true }],
      },
    },
    useFilters,
    useSortBy,
  );

  useEffect(() => {
    setFuelSolutionsLoading(true);
    api
      .getFuelSolutions(user!["https://ifuelsmart.com/company"])
      .then((data) => {
        console.log(data);
        setFuelSolutions(data);
        setFuelSolutionsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setFuelSolutionsLoading(false);
      });
  }, [user]);

  return (
    <>
      <div className="overflow-y-auto h-full">
        <div className="overflow-x-auto">
          <div className="align-middle inline-block min-w-full">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table
                className="relative min-w-full divide-y divide-gray-200"
                {...table.getTableProps()}
              >
                <thead className="bg-gray-50">
                  {table.headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          className="sticky top-0 group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          {...column.getHeaderProps({
                            ...column.getSortByToggleProps(),
                          })}
                          onClick={() => {
                            if (!column.canSort) return;
                            if (!column.isSorted) {
                              column.toggleSortBy(false, false);
                            } else {
                              column.toggleSortBy(!column.isSortedDesc, false);
                            }
                          }}
                        >
                          <div>
                            {column.render("Header")}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <AiFillCaretDown className="inline-block text-base" />
                                ) : (
                                  <AiFillCaretDown className="inline-block text-base rotate-180" />
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                          {column.canFilter ? column.render("Filter") : null}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  className="bg-white divide-y divide-gray-200"
                  {...table.getTableBodyProps()}
                >
                  {fuelSolutionsLoading && (
                    <tr>
                      <td colSpan={5} className="py-4">
                        <svg
                          className="animate-spin h-7 w-7 mx-auto"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </td>
                    </tr>
                  )}
                  {!fuelSolutionsLoading &&
                    table.rows.map((row) => {
                      table.prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td
                                className="px-6 py-4 whitespace-nowrap"
                                {...cell.getCellProps()}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
