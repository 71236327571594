import { useAuth0 } from "@auth0/auth0-react";
import IFrameDisplay from "components/iframe-display";
import { TractorsProvider } from "contexts/tractors-context";
import dashboardConfig from "dashboard-config";
import DashboardLayout from "layouts/dashboard-layout/dashboard-layout";
import BlogPage from "pages/app/blog";
import BlogSinglePage from "pages/app/blog-single";
import Blog from "pages/blog";
import Blogs from "pages/blogs";
import BotsPage from "pages/bots";
import FuelLocationsPage from "pages/fuel-locations-page/fuel-locations-page";
import FuelOptimzerPage from "pages/fuel-optimizer-page/fuel-optimizer-page";
import FuelPlanCompliencePage from "pages/fuel-plan-complience/fuel-plan-complience";
import LandingPage from "pages/landing-page/landing-page";
import TractorsPage from "pages/tractors-page/tractors-page";
import { Navigate, Route, Routes } from "react-router-dom";
import { FuelSolutionsIndex } from "pages/fuel-solutions";

const AuthApp = () => {
  const { user } = useAuth0();

  return (
    <>
      <Routes>
        <Route index element={<LandingPage />} />
        <Route path="bots/:id" element={<BotsPage />} />
        <Route path="blogs">
          <Route index element={<Blogs />} />
          <Route path=":slug" element={<Blog />} />
        </Route>
        <Route
          path="app"
          element={
            <>
              <style
                dangerouslySetInnerHTML={{
                  __html: `#bs-chatbot-widget-container__21E7F { display: none; }`,
                }}
              />
              <TractorsProvider>
                <DashboardLayout />
              </TractorsProvider>
            </>
          }
        >
          <Route index element={<FuelOptimzerPage />} />
          <Route path="fuel-solutions" element={<FuelSolutionsIndex />} />
          <Route path="fuel-locations" element={<FuelLocationsPage />} />
          <Route path="tractors" element={<TractorsPage />} />
          {["rvn", "ist", "we1", "anr", "mlh", "mnz", "cnl", "pg1", "lfc", "ind"].includes(
            user["https://ifuelsmart.com/company"]
          ) && <Route path="fuel-stop-compliance" element={<FuelPlanCompliencePage />} />}
          <Route path="blogs">
            <Route index element={<BlogPage />} />
            <Route path=":slug" element={<BlogSinglePage />} />
          </Route>
          {/* Custom Features Routes */}
          {dashboardConfig[user["https://ifuelsmart.com/company"]].customFeatures.map((feature) => (
            <Route
              key={feature.route}
              path={feature.route.split("/")[1]}
              element={<IFrameDisplay url={feature.iframeUrl} name={feature.name} />}
            />
          ))}
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </>
  );
};

export default AuthApp;
